import React from 'react';
import Header from '../components/Header';
import { use100vh } from "react-div-100vh"

const Thanks = () => (
  <div style={{height: use100vh()}} className="bg-primary flex relative">
    <div className="absolute top-0">
      <Header />
    </div>
    <div className="m-auto text-center">
      <h1 className="font-heading text-white text-7xl">Thank You!</h1>
      <p className="text-center text-white text-3xl mt-12">We'll get back to you soon.</p>
    </div>
  </div>
)

export default Thanks